import * as React from 'react'
import Layout from '../../components/layout'
import { Titulo } from '../../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'


export default function atencion () {
    const data=[
        {id:'1', name:'Jefatura 1° Básico', docente:'JAZMIN GARRIDO', hAtencion:'Jueves de 09:45 a 10:45 hrs'},
        {id:'2', name:'Jefatura 2° Básico', docente:'ELLYS AGUIRRE', hAtencion:'Miércoles de 08:00 a 09:15 hrs'},
        {id:'3', name:'Jefatura 3° Básico', docente:'ANDREA CABELLO', hAtencion:'Martes de 14:00 a 15:00 hrs'},
        {id:'4', name:'Jefatura 4° Básico', docente:'FRANCISCO NARRIAS', hAtencion:'Martes de 08:00 a 09:00 hrs'},
        {id:'5', name:'Jefatura 5° Básico', docente:'GUSTAVO PIZARRO', hAtencion:'Martes de 11:00 a 12:00 hrs'},
        {id:'6', name:'Jefatura 6° Básico', docente:'PAULINA RIOS', hAtencion:'Miércoles de 14:00 a 15:00 hrs'},
        {id:'7', name:'Jefatura 7° Básico', docente:'LEYLA JURE', hAtencion:'Martes de 15:30 a 16:30 hrs'},
        {id:'8', name:'Jefatura 8° Básico', docente:'TANIA CASTRO', hAtencion:'Lunes de 15:30 a 16:30 hrs'},
        {id:'8', name:'Docente de Religión', docente:'WILSON GALLEGUILLOS', hAtencion:'Martes de 15:30 a 16:30 hrs'},
        {id:'8', name:'Docente de Ed. Física', docente:'JUAN PABLO CARVAJAL', hAtencion:'Martes de 12:15 a 13:15 hrs'},
        {id:'8', name:'Docente de Inglés', docente:'SAMUEL JOFRE', hAtencion:'Jueves de 14:00 a 15:00 hrs'}
    ]
    return (
        <Layout>
            <Titulo title="Atencion apoderados"  />
            <Container>
                <Row className="text-center justify-content-center">
                    {data && data.map(e=>(
                        <Col key={e.id} md={4} className="mt-3">
                            <Card className="bg-dark">
                                <Card.Body>
                                    <strong><h5>{e.name}</h5></strong>
                                    <strong><h5>{e.docente}</h5></strong>
                                    <h6>{e.hAtencion}</h6>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    <Col md={12} className="mb-3"></Col>
                </Row>
            </Container>
        </Layout>
    )
}